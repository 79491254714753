









import { Component, Vue } from 'vue-property-decorator';

import TheGlobalNavHeader from '@/pages/TheGlobalNavHeader.vue';

@Component({
  components:{
    TheGlobalNavHeader,
  }
})
export default class TheGlobalBody extends Vue {

}
